
<template>
  <div class="bg">
    <topHeader title="戴了吗"></topHeader>
    <div class="equipment_map">
      <div class="content">
        <div>设备总数</div>
        <div v-if="equipmentMapData.equipment_total">
          {{ formatter(equipmentMapData.equipment_total) }}
        </div>
      </div>
      <chinaMap ref="Maps" class="map_div"></chinaMap>
    </div>

    <!-- 实时订单 -->
    <dv-border-box-8 :dur="4" class="grid" style="height: 40vh">
      <!-- 装饰角 -->
      <i class="top_left"></i>
      <i class="top_right"></i>
      <i class="bottom_left"></i>
      <i class="bottom_right"></i>
      <div class="title">
        <div class="title_text">实时订单</div>
      </div>
      <div id="orderList">
        <div class="order_table_header">
          <div class="nickname_header">名称</div>
          <div>类型</div>
          <div>数量</div>
          <div>时间</div>
        </div>
        <div class="order_table_body" v-if="realTimeOrderData.length > 0">
          <div
            :class="['order_table_item', index == 0 ? 'roll_class' : '']"
            :style="`top:${index * 40}px;`"
            v-for="(item, index) in realTimeOrderData"
            :key="item.orderId"
          >
            <div class="nickname">
              <img class="table_img" :src="item.avatar" alt="" />
              <span>{{ item.nickname.slice(0, 4) }}</span>
            </div>
            <div>{{ item.orderType == 0 ? "付费" : "免费" }}</div>
            <div>{{ item.goodsNum }}</div>
            <div>{{ item.orderCreateTime.split(" ")[1] }}</div>
          </div>
        </div>
      </div>
    </dv-border-box-8>
    <!-- 设备订单排行 -->
    <dv-border-box-8 :dur="4" class="grid" style="height: 48%">
      <!-- 装饰角 -->
      <i class="top_left"></i>
      <i class="top_right"></i>
      <i class="bottom_left"></i>
      <i class="bottom_right"></i>
      <div class="title">
        <div class="title_text">设备订单排行</div>
      </div>
      <!-- 设备订单排行柱状图 -->
      <div id="equipmentOrderBarChart"></div>
    </dv-border-box-8>
    <!-- 订单折线图 -->
    <dv-border-box-8 :dur="4" class="grid" style="height: 50%">
      <!-- 装饰角 -->
      <i class="top_left"></i>
      <i class="top_right"></i>
      <i class="bottom_left"></i>
      <i class="bottom_right"></i>
      <div class="title">
        <div class="title_text">订单数据</div>
        <div>{{ orderData.order_total }}（总数）</div>
      </div>
      <div id="orderLineChart"></div>
    </dv-border-box-8>
    <!-- 收益折线图 -->
    <dv-border-box-8 :dur="4" class="grid" style="height: 48%">
      <!-- 装饰角 -->
      <i class="top_left"></i>
      <i class="top_right"></i>
      <i class="bottom_left"></i>
      <i class="bottom_right"></i>
      <div class="title">
        <div class="title_text">收益补贴数据</div>
        <!-- <div>{{ profitData.profit_total }}（总数）</div> -->
      </div>
      <div id="earningsLineChart"></div>
    </dv-border-box-8>
    <!-- 会员折线图 -->
    <dv-border-box-8 :dur="4" class="grid" style="height: 48%">
      <!-- 装饰角 -->
      <i class="top_left"></i>
      <i class="top_right"></i>
      <i class="bottom_left"></i>
      <i class="bottom_right"></i>
      <div class="title">
        <div class="title_text">会员数据</div>
        <div>{{ memberData.member_total }}（总数）</div>
      </div>
      <div id="memberLineChart"></div>
    </dv-border-box-8>
  </div>
</template>

<script>
import topHeader from "./topHeader.vue";
import chinaMap from "../chinaMap";
import axiosApi from "@/utils/request.js";
export default {
  components: {
    topHeader,
    chinaMap,
  },
  data() {
    return {
      dataType: 1,
      memberData: {},
      profitData: {},
      equipmentOrderData: {},
      orderData: {},
      equipmentMapData: {},
      realTimeOrderData: [],
      timer: null,
      newData: [], //订单新数据集
      dataTime: null, //滚动定时函数
      tealtimeLastOrderId: null,
    };
  },
  mounted() {
    this.getEquipmentMapData();
    this.getMemberData();
    this.getProfitData();
    this.getEquipmentOrderData();
    this.getRealTimeOrderData();
    this.getOrderData();
    this.timer = setInterval(() => {
      // 定时更新
      this.getEquipmentMapData();
      this.getMemberData();
      this.getProfitData();
      this.getEquipmentOrderData();
      this.getRealTimeOrderData();
      this.getOrderData();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 会员数据
    getMemberData() {
      axiosApi(
        `/app-api/nlm/statistical/member?dataType=${this.dataType}`,
        {},
        "post"
      ).then((res) => {
        this.memberLoading = false;
        this.memberData = res.data.data;
        setTimeout(() => {
          this.drawMemberLineChart();
        }, 1000);
      });
    },
    // 收益数据
    getProfitData() {
      axiosApi(
        `/app-api/nlm/statistical/profit?dataType=${this.dataType}`,
        {},
        "post"
      ).then((res) => {
        this.profitLoading = false;
        this.profitData = res.data.data;
        setTimeout(() => {
          this.drawEarningsLineChart();
        }, 1000);
      });
    },
    // 设备订单排行数据
    getEquipmentOrderData() {
      axiosApi(
        `/app-api/nlm/statistical/equipment-order-top?dataType=${this.dataType}`,
        {},
        "post"
      ).then((res) => {
        this.equipmentOrderLoading = false;
        this.equipmentOrderData = res.data.data;
        setTimeout(() => {
          this.drawEquipmentOrderBarChart();
        }, 1000);
      });
    },
    // 订单数据
    getOrderData() {
      axiosApi(
        `/app-api/nlm/statistical/order?dataType=${this.dataType}`,
        {},
        "post"
      ).then((res) => {
        this.orderLoading = false;
        this.orderData = res.data.data;
        setTimeout(() => {
          this.drawoOrderLineChartChart();
        }, 1000);
      });
    },
    // 设备地图数据
    getEquipmentMapData() {
      axiosApi(
        `/app-api/nlm/statistical/equipment-map?dataType=${this.dataType}`,
        {},
        "post"
      ).then((res) => {
        this.equipmentMapData = res.data.data;
        this.$refs.Maps.hadleMap([], 1.2, [104.5, 35]);
        this.getEquipmentMapPageData(0, 1);
      });
    },
    // 设备地图数据分页
    getEquipmentMapPageData(type, page) {
      axiosApi(
        `/app-api/nlm/statistical/equipment-map-page?dataType=${type}&size=1000&page=${page}`,
        {},
        "post"
      ).then((res) => {
        if (res.data.data.equipment_list.length > 0) {
          this.$refs.Maps.addData(res.data.data.equipment_list);
          this.getEquipmentMapPageData(type, ++page);
        } else {
          if (this.dataType == 1 && type == 0) {
            this.getEquipmentMapPageData(1, 1);
          }
        }
      });
    },
    // 实时订单数据
    getRealTimeOrderData() {
      axiosApi(`/app-api/nlm/statistical/real-time-order`, {}, "post").then(
        (res) => {
          this.realTimeOrderLoading = false;
          let resData = [];
          if (this.tealtimeLastOrderId) {
            for (let i = 0; i <= res.data.data.length - 1; i++) {
              if (res.data.data[i].orderId == this.tealtimeLastOrderId) {
                break;
              }
              resData.push(res.data.data[i]);
            }
          } else {
            resData = [...res.data.data];
          }
          this.newData = [...this.newData, ...resData];
          // console.log(this.newData, "新数据");
          if (!this.dataTime) {
            // console.log("开始");
            this.arouselTime();
          }
          // 记录最新一条id
          this.tealtimeLastOrderId = res.data.data[0].orderId;
        }
      );
    },
    arouselTime() {
      this.dataTime = setInterval(() => {
        if (this.newData.length == 0) {
          clearInterval(this.dataTime);
          this.dataTime = null;
        } else {
          this.realTimeOrderData = [
            this.newData[this.newData.length - 1],
            ...this.realTimeOrderData.slice(0, 7),
          ];
          this.newData.pop();
        }
      }, 1000);
    },
    // 绘制会员折线图
    drawMemberLineChart() {
      let xData = [],
        yData = [];
      Object.keys(this.memberData.member_map).map((key) => {
        xData.push(key);
        yData.push(this.memberData.member_map[key]);
      });
      let memberLineChart = this.$echarts.init(
        document.getElementById("memberLineChart"),
        "dark"
      );
      let option = {
        backgroundColor: "transparent",
        grid: {
          left: 50,
          // top: 20,
          bottom: 70,
          // right: 50,
        },
        legend: {
          bottom: 10,
          // itemGap: 20,
          // itemHeight: 4,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          type: "value",
          minInterval: 1,
          name: "单位：个",
          nameGap: 30,
          nameTextStyle: {
            color: "#fff",
            fontWeight: 600,
          },
          axisLabel: {
            show: true,
            color: "#0EE7EB",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0EE7EB",
            },
          },
        },
        series: [
          {
            name: "会员",
            data: yData,
            type: "line",
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(0, 251, 239, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(0, 254, 161, 0.15)",
                },
              ]),
            },
            lineStyle: {
              color: "#00FEA1",
            },
            itemStyle: {
              color: "#fff",
            },
          },
        ],
      };
      memberLineChart.setOption(option);
    },
    // 绘制收益折线图
    drawEarningsLineChart() {
      let xData = [],
        yFreeData = [],
        yPayData = [];
      Object.keys(this.profitData.free_profit_map).map((key) => {
        xData.push(key);
        yFreeData.push(this.profitData.free_profit_map[key]);
        yPayData.push(this.profitData.pay_profit_map[key]);
      });

      let earningsLineChart = this.$echarts.init(
        document.getElementById("earningsLineChart"),
        "dark"
      );
      let option = {
        backgroundColor: "transparent",
        grid: {
          left: 50,
          // top: 20,
          bottom: 70,
          // right: 10,
        },
        legend: {
          bottom: 10,
          // itemGap: 20,
          // itemHeight: 4,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          type: "value",
          name: "单位：元",
          nameGap: 30,
          nameTextStyle: {
            color: "#fff",
            fontWeight: 600,
          },
          axisLabel: {
            show: true,
            color: "#0EE7EB",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0EE7EB",
            },
          },
        },
        series: [
          {
            name: `收益(总数：${this.profitData.pay_profit_amount})`,
            data: yPayData,
            type: "line",
            smooth: true,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(223, 152, 56, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(223, 152, 56, 0.15)",
                },
              ]),
            },
            lineStyle: {
              color: "#FF9300",
            },
            itemStyle: {
              color: "#FF9300",
            },
          },
          {
            name: `补贴(总数：${this.profitData.free_profit_amount})`,
            data: yFreeData,
            type: "line",
            smooth: true,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(14, 231, 235, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(7, 116, 118, 0.15)",
                },
              ]),
            },
            lineStyle: {
              color: "#0EE7EA",
            },
            itemStyle: {
              color: "#0EE7EA",
            },
          },
        ],
      };
      earningsLineChart.setOption(option);
    },
    // 绘制订单折线图
    drawoOrderLineChartChart() {
      let xData = [],
        yFreeData = [],
        yPayData = [];
      Object.keys(this.orderData.pay_order_map).map((key) => {
        xData.push(key);
        yFreeData.push(this.orderData.free_order_map[key]);
        yPayData.push(this.orderData.pay_order_map[key]);
      });
      let orderLineChart = this.$echarts.init(
        document.getElementById("orderLineChart"),
        "dark"
      );
      let option = {
        backgroundColor: "transparent",
        grid: {
          left: 50,
          // top: 20,
          bottom: 70,
          // right: 10,
        },
        legend: {
          bottom: 10,
          // itemGap: 20,
          // itemHeight: 4,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          type: "value",
          name: "单位：个",
          nameGap: 30,
          nameTextStyle: {
            color: "#fff",
            fontWeight: 600,
          },
          axisLabel: {
            show: true,
            color: "#0EE7EB",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#0EE7EB",
            },
          },
        },
        series: [
          {
            name: `购买(总数：${this.orderData.pay_order_total})`,
            data: yPayData,
            type: "line",
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(223, 152, 56, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(223, 152, 56, 0.15)",
                },
              ]),
            },
            lineStyle: {
              color: "#FF9300",
            },
            itemStyle: {
              color: "#FF9300",
            },
          },
          {
            name: `领取(总数：${this.orderData.free_order_total})`,
            data: yFreeData,
            type: "line",
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(14, 231, 235, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(7, 116, 118, 0.15)",
                },
              ]),
            },
            lineStyle: {
              color: "#0EE7EA",
            },
            itemStyle: {
              color: "#0EE7EA",
            },
          },
        ],
      };
      orderLineChart.setOption(option);
    },
    // 绘制设备柱状图
    drawEquipmentOrderBarChart() {
      let xData = [],
        yData = [],
        colorList = [
          new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: "rgba(0, 250, 255, 1)" },
            { offset: 1, color: "rgba(14, 249, 153, 1)" },
          ]),
          new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: "rgba(0, 254, 161, 1)" },
            { offset: 1, color: "rgba(255, 148, 0, 1)" },
          ]),
          new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [
            { offset: 0, color: "rgba(255, 148, 0, 1)" },
            { offset: 1, color: "rgba(0, 250, 255, 1)" },
          ]),
        ];
      this.equipmentOrderData.map((o) => {
        yData.push(`${o.topRank} ${o.snNo}`);
        xData.push({
          value: o.total,
          itemStyle: {
            color:
              o.topRank % 3 == 0
                ? colorList[2]
                : o.topRank % 2 == 0
                ? colorList[1]
                : colorList[0],
          },
        });
        return o;
      });
      let equipmentOrderBarChart = this.$echarts.init(
        document.getElementById("equipmentOrderBarChart"),
        "dark"
      );
      let option = {
        backgroundColor: "transparent",
        // legend: {
        //   bottom: 10,
        //   itemGap: 20,
        //   itemHeight: 4,
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: 110,
          top: 20,
          bottom: -30,
          right: 10,
        },
        xAxis: {
          max: "dataMax",
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: yData,
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          max: 5, // only the largest 3 bars will be displayed
          axisLabel: {
            color: "#fff",
            fontWeight: "bold",
            // fontSize: 16,
            align: "left",
            margin: 100,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            realtimeSort: true,
            name: "订单数",
            type: "bar",
            data: xData,
            label: {
              show: true,
              position: "inside",
              valueAnimation: true,
              color: "#000000",
            },
          },
        ],
      };
      equipmentOrderBarChart.setOption(option);
    },
    formatter(number) {
      const numbers = number.toString().split("").reverse();
      const segs = [];

      while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

      return segs.join(",").split("").reverse().join("");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/images/big_bg.png");
  background-size: cover;
  background-position: center center;
  height: 100vh;
  color: white;
  overflow-y: auto;
  padding: 0 14px;
}
.equipment_map {
  position: relative;
  .content {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 36px;
    position: absolute;
    top: 0;
  }
}
.map_div {
  width: 100%;
  height: 40vh;
  margin-bottom: 12px;
}

.grid {
  // border: 2px solid rgba(14, 231, 235, 0.5);
  position: relative;
  margin-bottom: 12px;
  padding: 4px;
}
.top_left,
.top_right,
.bottom_left,
.bottom_right {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(14, 231, 235, 1);
  position: absolute;
}
.grid .top_left {
  border-bottom-color: transparent;
  border-right-color: transparent;
  top: -3px;
  left: -3px;
}
.grid .top_right {
  border-bottom-color: transparent;
  border-left-color: transparent;
  top: -3px;
  right: -3px;
}
.grid .bottom_left {
  border-top-color: transparent;
  border-right-color: transparent;
  bottom: -3px;
  left: -3px;
}
.grid .bottom_right {
  border-top-color: transparent;
  border-left-color: transparent;
  bottom: -3px;
  right: -3px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 14px;
  padding: 16px;
  padding-bottom: 10px;
  .title_text {
    font-size: 16px;
    font-weight: bold;
  }
}
#equipmentOrderBarChart,
#orderLineChart,
#earningsLineChart,
#earningsLineChart,
#memberLineChart,
#orderList {
  height: calc(100% - 45px);
  overflow: hidden;
}
.border_bottom {
  border-bottom: 1px solid rgba(14, 231, 235, 0.5);
}
.order_table_header {
  margin-top: 10px;
  background: rgba(14, 231, 235, 0.5);
  color: rgba(14, 231, 235, 1);
  display: flex;
  justify-content: space-between;
  // text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  > div {
    width: 23%;
  }
  .nickname_header {
    width: 30%;
  }
}
.order_table_body {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.order_table_item {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  font-size: 16px;
  height: 40px;
  width: 100%;
  transition: top 500ms;
  > div {
    width: 23%;
  }
  .nickname {
    width: 30%;
    display: flex;
    align-items: center;
    .table_img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
}
.roll_class {
  animation-name: roll;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}
/* 动画代码 */
@keyframes roll {
  from {
    top: -40px;
  }
  to {
    top: 0;
  }
}
</style>