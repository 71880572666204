
<template>
  <div id="top-header">
    <div class="header_center">
      <!-- 戴了吗 -->
      <p class="title">
        <img src="@/assets/images/logo.png" alt="" srcset="" /><span>{{
          title
        }}</span>
      </p>
    </div>
    <div class="header_left">
      {{ localweather.city }}
      {{ localweather.wea }}
      {{ localweather.tem2 }}℃ - {{ localweather.tem1 }}℃
    </div>
    <div class="header_right">
      {{ dataTime.dateYear }} {{ dataTime.dateWeek }} {{ dataTime.dateDay }}
    </div>
  </div>
</template>

<script>
import { formatTime } from "../../utils/index.js";
import axios from "axios";
import axiosApi from "../../utils/request.js";
export default {
  name: "topHeader",
  props: {
    screen_logo: String,
    title: String,
  },
  data() {
    return {
      localweather: [],
      weatherImg: "",
      dataTime: {
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      },
    };
  },
  created() {
    this.getDateTime();
    this.cancelLoading();
    this.requestGetWeather();
  },
  methods: {
    // 获取时间
    getDateTime() {
      setInterval(() => {
        this.dataTime.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dataTime.dateWeek = this.dataTime.weekday[new Date().getDay()];
        this.dataTime.dateDay = formatTime(new Date(), "HH: mm: ss");
      }, 1000);
    },
    // 获取天气
    requestGetWeather() {
      var _this = this;
      axios
        .get(
          "https://v0.yiketianqi.com/api?unescape=1&version=v63&appid=86489365&appsecret=nQgYtIN8"
        )
        .then(function (response) {
          _this.localweather = response.data;
          // _this.weatherImg = 'http://tq.daodaoim.com//tianqiapi/skin/pitaya/' + response.data.wea_img +'.png'
        })
        .catch(() => {});
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
#top-header {
  color: white;
  padding: 20px 0;
  .header_center {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .title {
      width: 100%;
      font-size: 20px;
      color: #01e6f6;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 4px;
      img {
        height: 28px;
        margin-right: 10px;
      }
    }
  }
  .header_left,
  .header_right {
    font-size: 14px;
    text-align: center;
    margin: 12px 0;
  }
}
</style>
